.vertebral-row {
    position: relative;
    .vertebral-name {
        position: absolute;
        top: 50%;
        padding-left: 0.075rem;
        margin-top: -.5rem;
        color: black;
        font-size: 0.8rem;
    }

    .lead {
        position: absolute;
        z-index: 1;
        &.left {
            left: 4rem;
        }
        &.right {
            left: 7rem;
        }
        &.paddle {
            left: 4rem;
        }
    }

    .perc-distal {
        width: 2rem;
    }

    .paddle-distal {
        width: 5rem;
    }
}

