$color_1: #fff;
$color_2: #ffffff;
$color_3: #406e7b;

$color_6: #757982;

$color_17: white;

$color_19: #666666;
$font-family_1: "Montserrat", "Helvetica Neue", Arial, sans-serif;
$font-family_2: tahoma;
$background-color_1: black;
$background-color_2: #006898;
$background-color_3: #28b5c6;
$background-color_4: #c1e470;
$background-color_5: #38d5a2;
$background-color_6: gray;
$background-color_7: #00B3E3;

$background-color_14: #ffffff;

$background-color_17: #0069A6;
$background-color_18: white;
$background-color_19: #cccccc;
$border-color_1: #00B3E3;
$border-color_2: #07a5d0;
$border-color_3: #979797;


.ant-picker-header-super-next-btn, .ant-picker-header-super-prev-btn{
    display: none;
    }


	.buttons-lead{
		gap: 0px !important;
	}

.tooltip {
	position: relative;
	display: inline-block;
	border: 1px solid black;
	.tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: $background-color_1;
		color: $color_1;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
	}
	&:hover {
		.tooltiptext {
			visibility: visible;
		}
	}
}

td {
	// background: #e0eff6;
	position: relative;
	.data {
		background: #C9C4D4 !important;
		border-radius: 10px;
		height: 20px;
		overflow: hidden;
		width: 67px;
	}
	.dataLegend {
		background: #fff;
		border-radius: 20px;
		height: 10px;
		overflow: hidden;
		width: 50px;
	}
	.grey_data {
		background: #C9C4D4;
	}
	.white_data {
		// background: #ddeef5;
		background-color: #DDEEF5;
		opacity: 70%;
	}
	.blue_data {
		background: #5AC8FA;
	}
	.green_data {
		background: #46CD92;
	}
	.yellow_data {
		background: #C5E17E;
	}
	.time_data {
		background: #006898;
	}
	.time_Blue_Data_Gradient {
		background-image: repeating-linear-gradient(-45deg, white 0px, white 2px, #5AC8FA 2px, #5AC8FA 4px);
	}
	.time_Green_Data_Gradient {
		background-image: repeating-linear-gradient(-45deg, white 0px, white 2px, #46CD92 2px, #46CD92 4px);
	}
	.time_Yellow_Data_Gradient {
		background-image: repeating-linear-gradient(-45deg, white 0px, white 2px, #C5E17E 2px, #C5E17E 4px);
	}
	.time_Data_Gradient {
		background-image: repeating-linear-gradient(-45deg, white 0px, white 2px, #006898 2px, #006898 4px);
	}
	.time_radial_gradient {
		background-color: $background-color_2;
		background-image: radial-gradient(#fff 10%, transparent 11%), radial-gradient(#fff 10%, transparent 11%);
		background-size: 20px 20px;
		background-position: 0 0, 15px 15px;
		background-repeat: repeat;
	}
	.time_radial_gradient_blue {
		background-color: #5AC8FA;
		background-image: radial-gradient(#fff 10%, transparent 11%), radial-gradient(#fff 10%, transparent 11%);
		background-size: 20px 20px;
		background-position: 0 0, 15px 15px;
		background-repeat: repeat;
	}
	.time_radial_gradient_yellow {
		background-color: #C5E17E;
		background-image: radial-gradient(#fff 10%, transparent 11%), radial-gradient(#fff 10%, transparent 11%);
		background-size: 20px 20px;
		background-position: 0 0, 15px 15px;
		background-repeat: repeat;
	}
	.time_radial_gradient_green {
		background-color: #46CD92;
		background-image: radial-gradient(#fff 10%, transparent 11%), radial-gradient(#fff 10%, transparent 11%);
		background-size: 20px 20px;
		background-position: 0 0, 15px 15px;
		background-repeat: repeat;
	}
}
th {
	.head_data {
		background: #58595b;
		color: $color_1;
		border-radius: 10px;
		height: 20px;
		width: 67px;
		font-size: 14px;
		line-height: 20px;
		align-items: center;
	}
}
.style_header_bipole {
	width: 100%;
	background: #0069A6 !important;
	color: $color_2;
	padding: 5px 5px;
}

.LeadTableMain{
	display: flex;
	align-items: center;
}
.table {
	>tbody {
		>tr {
			>td {
				padding: 4.5px;
				border: 0px;
			}
			>th {
				padding: 4.5px;
				border: 0px;
			}
		}
	}
	>tfoot {
		>tr {
			>td {
				padding: 4.5px;
				border: 0px;
			}
			>th {
				padding: 4.5px;
				border: 0px;
			}
		}
	}
	>thead {
		>tr {
			>td {
				padding: 4.5px;
				border: 0px;
			}
			>th {
				padding: 4.5px;
				border-width: 0;
				border-bottom-width: 1px;
				font-size: 1.25em;
				font-weight: 500;
				font-family: $font-family_1;
				color: $color_6;
			}
		}
	}
}
.table3 {
	tbody {
		>tr {
			>td {
				padding: 5px 8px;
			}
		}
	}
	thead {
		>tr {
			>th {
				text-align: left;
				padding: 5px 8px;
			}
		}
	}
}
.data_flow {
	display: inline-block;
	height: 100%;
	width: 100%;
	color: $color_1;
	z-index: 1;
	text-align: center;
}
.table_head {
	color: $color_6;
}
.f12{
	height: 38px;
}
.darkgrey_data1 {
	background: #3c4041;
}
.darkgrey_data2 {
	background: #53565b;
}
.darkgrey_dataNotShown {
	background-color: $background-color_6;
}
.hyphen {
	border-top: 1px solid #607D8B;
	// display: inline-block;
	// display: contents;
	width: 20px;
	position: relative;
	// top: -5px;
	// right: -5px;
}

.floatL {
	float: left;
}


.row {
	margin-right: 0 !important;
	margin-left: 0 !important;
	display: block;
}
.col {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.col-md {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.table_datasheet tbody tr, .table_small tr{
    height:26px
}

.data_4 {
	width: 40px !important;
	height: 10px !important;
}
.f11 {
	font-size: 12px;
	color: #2C2A29;
}
.chartAndFilter {
	display: flex;
}
.legend {
	display: flex;
	border: 1px solid rgb(201, 202, 212);
    border-radius: 6px;
}
.leadDiagram {
	width: 20%;
}
.container {
	display: flex;
	.bipole {
		background-color: $background-color_14;
		display: flex;
	}
	padding-top: 20px;
}
.amplitude {
	text-align: center;
	font-size: 12px;
	color: $color_6;
}
.filterAndLegend {
	display: flex;
	.legend-padding {
		flex: 0 1 90px;
	}
}
.bipoleFilters {
	width: 75%;
	background-color: $background-color_14;
	display: flex;
	height: 120px;
}
.filterTable {
	width: 75%;
	display: flex;
}
.filterTableProgram {
	overflow-y: scroll;
}
.legendText {
	font-size: 14px;
	
}
.customButtons {
	display: flex;
	flex-direction: column;
}
.filterButton {
	background-color: $background-color_7;
}
.modalViewButton {
	background-color: $background-color_7;
	width: 45%;
	color: $color_17;
	border: 0;
	border-radius: 6px;
	height: 50px;
	&:disabled {
		border: 1px solid #999999;
		background-color: $background-color_19;
		color: $color_19;
	}
}


.CircularProgressbar{
	width: 50px !important
}

.therapyDuration{
	display: flex;
	justify-content: space-between;
}
.assessment {
	background-color: $background-color_18;
	width: 250px;

}
.datePickerAndFilter {
	display: flex;
	flex-direction: column;
}
.customDatePicker {
	display: flex;
	flex-direction: column;
}
.fromDate {
	display: flex;
	width: 300px;
}
.toDate {
	display: flex;
}
.patientDetails {
	display: flex;
}
.programTable {
	display: grid;
	grid-template-columns: auto auto auto;
	padding: 10px;
}
.modalView {
	width: 30%;
	background: white;
	padding: 3rem;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}
.overLay {
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
}
.tableModalView {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(6, 1fr);
	grid-column-gap: 1em;
}
.modalView-buttons {
	display: flex;
	justify-content: space-evenly;
	padding: 5px;
}
.filterButtons {
	padding: 20px;
	display: flex;
	justify-content: space-evenly;
	width: 300px;
}
.programFilter {
	width: 200px;
}
.heartImage {
	position: absolute;
	z-index: 1;
	object-fit: contain;
	padding-left: 25px;
	img {
		vertical-align: inherit;
	}
}
.legendSeriesInput {
	display: flex;
	height: 20px;
}
.legendSeries {
	display: flex;
	flex-direction: column;
}
.sideNavigation {
	display: flex;
}

.chart-area {
	max-width: calc(100% - 110px);
	width: 100%;
	height: 100%;
	.no-data-container {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	}
	#chartdiv {
	  width: 100%;
	  height: 100%;
	}
  }
@media screen and (max-width: 991px) {
	.navbar {
		.dropdown.show {
			.dropdown-menu {
				display: block;
				position: absolute;
				background: red !important;
				margin: 0 !important;
			}
		}
	}
}

