.nav-menu-container {
  background-color: #00415f !important;
  height: 100%;
  .nav-menu-logo {
    text-align: center;
    padding-top: 8px;
    img {
      width: 120px;
    }
  }
}

.nav-menu {
  background-color: #00415f !important;
}

.divider {
  background-color: #006e9f;
  margin: 16px 0;
}

:global(.ant-menu-title-content) {
  color: #c1d6e0;
}

:global(.ant-menu.ant-menu-sub.ant-menu-inline) {
  background-color: #00415f;
}

:global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background-color: #00415f !important;
}

:global(.ant-menu-item-selected) {
  background-color: #006e9f !important;
}

.patient-menu-item {
    height: 50px !important;
  .patient-link {
    line-height: 150%;
    .patient-id {
      color: white;
    }
  }
}

.device-menu-item {
  height: 70px !important;
  color: #9ad9e9 !important;
  line-height: 200% !important;
  cursor: default;

  .device-select {
    width: 100%;
    color: white;
    :global(.ant-select-selector) {
        padding: 0 !important;
    }
    :global(.ant-select-arrow) {
      color: #9ad9e9;
    }
  }
}

:global(.ant-menu-title-content) {
  font-size: 14px;
}

:global(.ant-menu-item-divider) {
  border-color: #006e9f !important;
}

:global(.ant-menu:not(.ant-menu-horizontal)) {
  background-color: red;
}

:global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
  line-height: 1.35 !important;
}
