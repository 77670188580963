html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

#root {
  height: 100%;
}

section {
  height: 100%;
}