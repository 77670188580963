$color_6: #757982;

.legend-container {
  flex: 1 1 720px;
  display: flex;
  border: 1px solid rgb(201, 202, 212);
  border-radius: 6px;
  font-size: 12px;
  padding: 5px 8px;
  gap: 0 5px;

  .legend-heading {
    font-size: 14px;
    color: $color_6;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  }

  .legend-items-container {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    > div {
      flex: 0 1 150px;
      > div {
        white-space: pre;
      }
    }
  }
}

.pill-container {
  display: inline-block;
  vertical-align: middle;
  background: #c9c4d4;
  border-radius: 20px;
  height: 10px;
  overflow: hidden;
  width: 50px;
  margin: 5px 8px;
  position: relative;
}

.blue {
  background-color: #5ac8fa;
}
.green {
  background-color: #46cd92;
}
.yellow {
  background-color: #c5e17e;
}
.gradient {
  background-image: repeating-linear-gradient(-45deg, #fff, #fff 2px, #006898 0, #006898 4px);
}
.radial-gradient {
  background-image: radial-gradient(#fff 10%, transparent 11%),
    radial-gradient(#fff 10%, transparent 11%);
  background-position: 0 0, 15px 15px;
  background-repeat: repeat;
  background-size: 20px 20px;
}
.duration {
  background-color: #006898;
}
.fifty {
  width: 50%;
  height: 100%;
}
.twentyfive {
  width: 25%;
  height: 100%;
}
.heart {
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 10px;
}
