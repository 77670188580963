.chart-area {
    width: 100%;
    height: 100%;
    .no-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #chartdivTherapy {
      width: 100%;
      height: 100%;
    }
  }

.legendSubFilter{
	color: #757982;
    font-size: 12px;
}

.filterIcon{
	padding: 5px;
}



.inWindowFilter{
font-style: italic;
font-weight: 400;
font-size: 12px;
line-height: 16px;
padding-left: 2px;
color: #757982;
margin-bottom: 0px;
}

.inwindowLabel{
	margin-bottom: 0px;;
}

.tooltip-container {
  background-color: white;
  .tooltip-content-row {
    border-bottom: 1px solid grey;
    td:last-child {
      font-weight: bold;
      text-align: right;
    }
  }
}

.area-tooltip-table {
  display: inline-block; 
  .tooltip-area-content-row {
    border-bottom: 1px solid grey;
    td:last-child {
      text-align: right;
      font-weight: bold;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border-color: black;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid black;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}

.filterSeriesText{
    display: inline-block;
    font-size: 11px;
    color: black;
    font-weight: 500;
    font-style: normal;
    line-height: 16px;
}






