.container {
  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.program-container {
  margin-bottom: 4rem;
}

.program-grid {
  margin-top: 2rem;
  width: 430px;
  .label {
    font-weight: bold;
    &.on {
      color: green;
    }
    &.off {
      color: orange;
    }
  }
  .value {
    float: right;
  }
  .divider {
    border-top-color: #c9cad4;
    margin: 0.5rem 0;
  }
}

.assessment-table {
  margin: 1rem 0;
  width: 100%;
  max-width: 1224px;
  th,
  td {
    width: 14%;
    padding: 10px 5px;
  }
  th {
    text-align: left;
    border-bottom: 1px solid #444646;
    background-color: #f6f7f7;
  }
  td {
    border-bottom: 1px solid #dcdfdf;
  }

  .assessment-circle {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-top: 0.2em;
    margin-right: 0.3em;
    &.inside {
      background-color: #509f33;
    }
    &.outside {
      background-color: #f2a900;
    }
    &.pain-relief {
      width: 0.67rem;
      height: 0.67rem;
    }
    &.low {
      background-color: #fc4d0f;
    }
    &.medium {
      background-color: #ffd82d;
    }
    &.high {
      background-color: #509f33;
    }
  }

  .assessmentType {
    display: flex;
    flex-direction: row;
  }

  .star {
    position: relative;

    display: inline-block;
    width: 0;
    height: 0;

    margin-left: 0.3em;
    margin-right: 0.9em;
    margin-bottom: 1.2em;

    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 0.3em solid transparent;

    /* Controlls the size of the stars. */
    font-size: 12px;

    &:before,
    &:after {
      content: "";

      display: block;
      width: 0;
      height: 0;

      position: absolute;
      top: 0.6em;
      left: -1em;

      border-right: 1em solid transparent;
      border-bottom: 0.7em solid #fc0;
      border-left: 1em solid transparent;

      transform: rotate(-35deg);
    }

    &:after {
      transform: rotate(35deg);
    }
  }
}
