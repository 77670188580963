.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        text-align: center;
        h2 {
            margin-top: 2rem;
            font-size: 1.6rem;
            font-weight: bold;
        }
        p {
            font-size: 1.2rem;
        }
    }
}