.content {
  display: flex;
  height: 100%;

  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }

  .refresh-button {
    margin: 0 1rem 1rem;
    border: 1px solid;
    border-radius: 6px;
    border-color: #c9cad4;
    font-size: 11px;
    color: #757982;
  }

  .button-icon {
    margin-right: 0.5rem;
    vertical-align: text-bottom;
  }

  .chart-column {
    flex: 1 1 auto;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    .chart-column-button {
      flex: 0 1;
    }
    .chart-column-chart {
      overflow-y: auto;
      flex: 1 1;
    }

    .chart-column-chart-bipole {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }

  .filter-drawer {
    flex: 0 1 auto;
    width: 0;
    position: relative;
    padding-top: 0.2rem;
    padding-bottom: 0.8rem;
    > * {
      padding: 0 0.8rem;
    }
    &.open {
      border: 1px solid #c9cad4;
      border-radius: 6px;
      width: 250px;
      height: 100%;
      overflow-y: auto;
      .filter-drawer-content {
        display: block;
      }
      .filter-button {
        position: relative;
        right: unset;
        :after {
          content: " »";
        }
      }
      .clear-filters-button {
        display: inline-block;
      }
    }

    :global(.ant-divider-horizontal) {
      margin: 12px 0;
    }
    :global(.ant-picker-range-separator) {
      padding-left: 0;
      padding-right: 4px;
    }

    .filter-button {
      font-weight: bold;
      position: absolute;
      right: 0;
      margin-left: 2rem;
    }

    .clear-filters-button {
      color: #fc4d0f;
      float: left;
      display: none;
      .clear-filters-icon {
        margin-right: 0.4rem;
        vertical-align: middle;
        margin-top: -0.3rem;
      }
    }

    .filter-drawer-content {
      display: none;
      color: #757982;
    }
  }
}
