.chart-area {
  width: 100%;
  height: 100%;
  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #chartdiv {
    width: 100%;
    height: 100%;
  }
}

.lead-grid {
  display: flex;
  div {
    flex: 1;
  }
}

.lead-buttons {
  :global(.ant-radio-button-wrapper) {
    line-height: 24px;
    height: 27px;
  }
}

.spacer {
  width: 100%;
}

.chart-legend {
  width: 225px;
  height: 250px;
}

.tooltip-container {
  display: grid;
  grid-template-columns: 85px 100px;
  padding: 0.5rem;
  .tooltip-container-label {
    padding: 0.25rem 0;
    font-weight: bold;
    &:not(:nth-last-child(2)) {
      border-bottom: 1px solid #c9cad4;
    }
  }
  .tooltip-container-value {
    padding: 0.25rem 0;
    text-align: right;
    &:not(:last-child) {
      border-bottom: 1px solid #c9cad4;
    }
  }
}
